/************************* home page css start from here  ***********************************/

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 20px;
}

.page-title {
  font-size: 24px;
  color: #4bb4f1;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #4bb4f1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dashboard Cards */
.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.card-title {
  font-size: 16px;
  color: #e6f7ff;
}

.card-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(75, 180, 241, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4bb4f1;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
  color: #4bb4f1;
}

.card-label {
  font-size: 14px;
  color: #a6a6a6;
}
/************************* home page css end here  ***********************************/

/************************* punch css start from here  ***********************************/
.punch-container {
  color: #ffff;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  z-index: 1;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.punch-container .punch {
  margin: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: #fff;
  display: flex;
  gap: 20px;
}
.punch-container .punch p {
  font-size: 20px;
}
.punch-container .punch button {
  width: 250px;
  height: 40px;
  cursor: pointer;
  font-size: 20px;
}
.punch-container .card {
  width: 500px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  padding: 15px;
  margin: 20px;
}

/* new css */
.container {
  display: flex;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  overflow: hidden;
}

.left-panel,
.right-panel {
  padding: 20px;
  flex: 1;
}

.left-panel {
  flex: 2;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.month-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 20px;
  color: #4bb4f1;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th,
.attendance-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.attendance-table th {
  background-color: rgba(75, 180, 241, 0.1);
  color: #4bb4f1;
  font-weight: bold;
}

.date-cell {
  font-weight: bold;
  color: #e6f7ff;
}

.weekend {
  color: #ff7875;
}

.status {
  text-align: center;
  color: #52c41a;
}

.form-section {
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.03);
  padding: 20px;
  border-radius: 8px;
}

.form-section h3 {
  font-size: 1.1em;
  margin-bottom: 15px;
  color: #4bb4f1;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-size: 0.9em;
  margin-bottom: 8px;
  color: #e6f7ff;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  font-size: 0.9em;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: #ffffff;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4bb4f1;
  box-shadow: 0 0 0 2px rgba(75, 180, 241, 0.2);
}

.form-group textarea {
  resize: vertical;
}

.button-group {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 10px 16px;
  border: none;
  background: linear-gradient(180deg, #36b0c9 0%, #003f84 100%);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin-top: 10px;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/************************* attendance page css start from here  ***********************************/

/************************* attendance page css end from here  ***********************************/

/************************* leave page css start from here  ***********************************/

.leave-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.year-filter {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  border-bottom: 1px solid #162844;
}

.year-filter button {
  background: transparent;
  border: none;
  color: #8892b0;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
}

.year-filter button.active {
  color: #ff4444;
}

.year-filter button.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff4444;
}

.ticket-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket-card {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.ticket-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  background: #0a192f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4bb4f1;
}

.ticket-details h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.ticket-details p {
  font-size: 13px;
  color: #8892b0;
}

.ticket-status {
  display: flex;
  align-items: center;
  gap: 15px;
}

.status-badge {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.more-options {
  color: #8892b0;
  cursor: pointer;
}
/************************* leave page css end from here  ***********************************/

/************************* leave apply page css start from here  ***********************************/

.leave-apply-container {
  color: #a6a6a6;
}
.leave-apply-container .profile-info h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.leave-apply-container .profile-info p {
  color: #a6a6a6;
  font-size: 14px;
}

.leave-apply-container .form-section h3 {
  color: #a6a6a6;
}

.leave-apply-container .working-options {
  display: flex;
  gap: 10px;
}

.leave-apply-container .date-inputs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.leave-apply-container .date-input {
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  font-size: 0.9em;
  color: #ffffff;
}

.leave-apply-container .reason-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 0.9em;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

/************************* leave apply page css end from here  ***********************************/

/************************* profile css popup css start from here  ***********************************/
.popup-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  width: 240px;
  background-color: #002345;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  padding: 8px 0;
}

.popup-menu .user-info {
  padding: 12px 16px;
  border-bottom: 1px solid #80878d;
}

.popup-menu .user-name {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0 0 4px 0;
}

.popup-menu .user-email {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.popup-menu .menu-item {
  display: block;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s;
}

.popup-menu .menu-item:hover {
  background-color: #0d2d4d;
}

.popup-menu .menu-divider {
  height: 1px;
  background-color: #80878d;
  margin: 8px 0;
}

.popup-menu .logout {
  color: #dc2626;
  font-size: 16px;
  font-weight: 500;
}

/* Optional: Add animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-menu .popup-menu {
  animation: fadeIn 0.2s ease-out;
}
