.panel-container {
  background: linear-gradient(135deg, #001529 0%, #003366 100%);
  background-size: cover;
  height: 100vh;
  display: flex;
}

.panel-container .sidebar {
  flex: 0 0 200px;
}
.panel-container .sidebar {
  width: 250px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  padding: 20px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.panel-container .brand {
  padding: 0 24px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.panel-container .brand .brand-title {
  font-size: 24px;
  color: #4bb4f1;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
}

.panel-container .sidebar ul {
  padding: 0px;
  color: #fff;
}

.panel-container .sidebar ul li a {
  display: flex;
  color: #fff;
  gap: 12px;
  align-items: center;
  font-family: Philosopher;
  font-weight: 800;
  text-decoration: none;
  padding: 10px;
}
.panel-container .sidebar ul li a:hover {
  background: rgba(75, 180, 241, 0.1);
  cursor: pointer;
  color: #4bb4f1;
}
.panel-container .sidebar ul li .active {
  background: rgba(75, 180, 241, 0.1);
  cursor: pointer;
  color: #4bb4f1;
}

.panel-main-content {
  height: 100vh;
  width: 100%;
  color: #ffff;
  height: 100vh;
  overflow: auto;
  height: 100vh;
  overflow: auto;
  padding: 20px;
}
