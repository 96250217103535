* {
  margin: 0px;
  pad: 0px;
  box-sizing: border-box;
  position: relative;
}

.error-message {
  color: #ff0000;
}
.error-border {
  border: 2px solid #ff0000 !important;
}
