.salary-details-container .header {
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.salary-details-container .logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.salary-details-container .company-name {
  font-size: 24px;
  margin: 10px 0;
}

.salary-details-container .company-address {
  font-size: 14px;
  color: #a2a3a4;
  margin: 5px 0;
}

.salary-details-container .payslip-title {
  font-size: 16px;
  margin: 20px 0;
}

.salary-details-container .employee-details {
  border: 1px solid #80878d;
  margin: 20px 0;
  display: flex;
}

.salary-details-container .employee-left,
.salary-details-container .employee-right {
  flex: 1;
  padding: 10px;
}

.salary-details-container .detail-row {
  display: flex;
  margin: 8px 0;
}

.salary-details-container .detail-label {
  width: 150px;
  font-weight: normal;
}

.salary-details-container .detail-value {
  flex: 1;
}

.salary-details-container .salary-details {
  border: 1px solid #80878d;
  margin: 20px 0;
}

.salary-details-container .salary-header {
  display: flex;
  border-bottom: 1px solid #80878d;
  font-weight: bold;
}

.salary-details-container .salary-column {
  flex: 1;
  padding: 8px;
}

.salary-details-container .amount-column {
  width: 120px;
  padding: 8px;
  text-align: right;
}

.salary-details-container .salary-row {
  display: flex;
}

.salary-details-container .salary-component {
  flex: 1;
  padding: 8px;
}

.salary-details-container .amount {
  width: 120px;
  padding: 8px;
  text-align: right;
}

.salary-details-container .total-row {
  display: flex;
  border-top: 1px solid #80878d;
  font-weight: bold;
}

.salary-details-container .net-pay {
  margin: 20px 0;
  font-weight: bold;
}

.salary-details-container .footer {
  margin-top: 40px;
  text-align: center;
  font-size: 12px;
  color: #666;
}

.salary-details-container .separator {
  border-top: 1px solid #80878d;
  margin: 20px 0;
}
